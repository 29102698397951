import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const LeftColumn = styled(Box)`
	height: 100%;
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 4rem;
	@media only screen and (max-width: 500px) {
		padding-left: 2rem;
		padding-right: 2rem;
		max-height: 800px;
	}
`;

export const Content = styled(Box)`
	@media only screen and (max-width: 500px) {
		height: 100%;
    	overflow-y: auto;
	}
`;

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const DownloadDialog = ({ openDialog, onCloseDialog }) => {
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false);
    onCloseDialog(false);
  };

  React.useEffect(() => {
    openDialog && setOpen(true)
  }, [openDialog])

  const onDownload = () => {
    window.open("assets/TurnTheirGaze.pdf");
    handleClose(false);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Turn their gaze</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Download a free summary of this presentation by submitting the form below.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button variant="contained" onClick={onDownload} autoFocus>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

import React from "react";
import Box from "@mui/material/Box";

const text = {color:"white",fontSize:"18px",fontWeight:"400",lineHeight:"1.5",}

const PaddedBox = ({ children, style }) => {
  return ( 
    <span style={{...style, display: 'inline', paddingBottom: '15px'}}>{children}</span>
  )
}

export const Intro = () => {
  return (
    <Box display="flex" flexDirection="column" id="intro-container" style={{maxWidth:"450px", ...text}}>
      <PaddedBox style={{ fontWeight:"bold",fontSize:"36px",color:"white",marginBottom:"15px", textTransform: 'uppercase'}}>
        Turn their gaze
      </PaddedBox>
      <PaddedBox>
        The attention-grabbing properties of the Guadalupe tilma have yet to be
        fully discussed, since it first appeared in human history on 12th
        December 1531.
      </PaddedBox>
      <PaddedBox> 
        On 12th December 2016, this gap started to be filled with a 5-minute
        presentation at The Church of Our Lady of the Immaculate Conception,
        Rotherhithe, London, UK.
      </PaddedBox>
      <PaddedBox>
        Download a free summary of this presentation by submitting the form
        below
      </PaddedBox>
    </Box>
  );
};

import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { validEmail } from "../../util/email.util";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { api } from "../../api/api.config";
import { DownloadDialog } from "../Dialog/DownloadDialog";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export default function ContactForm() {
  const [form, setForm] = useState({ name: "", email: "" });
  const [openDialog, setOpenDialog] = useState(false);
  const [validateInfo, setValidateInfo] = useState({
    message: "",
    open: false,
  });

	const onSubmit = () => {
		if (!validEmail(form.email)) {
			setValidateInfo({ message: "please enter a valid email", open: true });
			return;
		}

    const token = process.env.REACT_APP_EMAIL_TOKEN

    api.post("send", { ...form, token }).then(() => {
      setOpenDialog(true);
    });
  };

	const updateForm = (field, event) => {
		setForm({ ...form, [field]: event.target.value });
	};

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      sx={{
        "& > :not(style)": { m: 1 },
        width: "100%",
      }}
      ml={"-16px"}
      noValidate
      autoComplete="off"
    >
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.REACT_APP_RECAPTCHA}
        scriptProps={{
          async: false, // optional, default to false,
          defer: false, // optional, default to false
          appendTo: "head", // optional, default to "head", can be "head" or "body",
          nonce: undefined, // optional, default undefined
        }}
      >
        <DownloadDialog
          openDialog={openDialog}
          onCloseDialog={() => setOpenDialog(false)}
        />
        <Snackbar
          onClose={() => {
            setValidateInfo({ ...validateInfo, open: false });
          }}
          autoHideDuration={4000}
          anchorOrigin={{ horizontal: "left", vertical: "top" }}
          open={validateInfo.open}
        >
          <Alert severity="error" sx={{ width: "100%" }}>
            {validateInfo.message}
          </Alert>
        </Snackbar>
        <TextField
          label="Name"
          value={form.name}
          fullWidth
          onChange={(event) => updateForm("name", event)}
          InputProps={{ style: { backgroundColor: "white" } }}
          variant="filled"
        />
        <TextField
          label="Email"
          type="email"
          fullWidth
          onChange={(event) => updateForm("email", event)}
          InputProps={{ style: { backgroundColor: "white" } }}
          variant="filled"
        />
        <Button onClick={onSubmit} sx={{ width: "302px" }} variant="contained">
          Submit
        </Button>
      </GoogleReCaptchaProvider>
    </Box>
  );
}

import React from "react";
import Box from "@mui/material/Box";
import { Carousel as CarouselCore } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const Carousel = () => {
  return (
    <>
      <Box style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,.3)'}} />
      <CarouselCore
        showThumbs={false}
        showArrows={false}
        showStatus={false}
        dynamicHeight
        autoPlay
        interval={10000}
        animationHandler="fade"
        swipeable={false}
        infiniteLoop
      >
          <img alt="Our Lady of Guadalupe" src="assets/1.jpeg" />
          <img alt="Our Lady of Guadalupe Perspective 2" src="assets/2.jpeg" />
          <img alt="Our Lady of Guadalupe Perspective 1" src="assets/3.jpeg" />
      </CarouselCore>
    
    </>
  );
};

export default Carousel;

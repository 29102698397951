import "./App.css";
import Carousel from "./components/Carousel/Carousel";
import { Intro } from "./components/Intro/Intro";
import ContactForm from "./components/ContactForm/ContactForm";
import { Content, LeftColumn } from "./layout/LeftColumn.styles";

function App() {
  return (
    <div className="app">
      <Carousel />
      <LeftColumn id="left-column">
        <Content id="content">
          <Intro />
          <ContactForm />
        </Content>
      </LeftColumn>
    </div>
  );
}

export default App;
